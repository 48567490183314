var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('validation-observer', {
    ref: "form"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "4",
      "xl": "4"
    }
  }, [_c('b-card-actions', {
    attrs: {
      "title": _vm.pb.no_invoice,
      "action-collapse": ""
    }
  }, [_c('b-card-title', [!_vm.isInvoiceAvailable ? _c('b-form-group', [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Pilih No.Invoice")]), _c('v-select', {
    attrs: {
      "reduce": function reduce(option) {
        return option.value;
      },
      "options": _vm.invoiceList,
      "label": "text"
    },
    model: {
      value: _vm.selectedInvoice,
      callback: function callback($$v) {
        _vm.selectedInvoice = $$v;
      },
      expression: "selectedInvoice"
    }
  })], 1) : _vm._e()], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "12",
      "xl": "12"
    }
  }, [_c('b-button', {
    attrs: {
      "id": "popover-button-3",
      "variant": "outline-primary",
      "block": ""
    },
    on: {
      "click": function click($event) {
        _vm.show = !_vm.show;
      }
    }
  }, [_vm._v(" Petunjuk ")]), _c('b-popover', {
    attrs: {
      "show": _vm.show,
      "target": "popover-button-3",
      "placement": "top",
      "triggers": "click"
    },
    on: {
      "update:show": function updateShow($event) {
        _vm.show = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm._v(" Petunjuk ")];
      },
      proxy: true
    }])
  }, [_c('ol', [_c('li', [_vm._v("Pilih salah satu barang")]), _c('li', [_vm._v("Tunggu beberapa detik, karena sistem mengecek barang tersebut sudah mempunyai penyimpanan atau belum")]), _c('li', [_vm._v("Jika punya penyimpanan, admin langsung ceklis salah satu penyimpanan barang tersebut")]), _c('li', [_vm._v("Lalu isi jumlah stok")]), _c('li', [_vm._v("Tapi jika barang tersebut belum mempunyai penyimpanan, maka harus buat penyimpanan terlebih dahulu")])])])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "8",
      "xl": "8"
    }
  }, [_c('b-card', {
    attrs: {
      "title": "Pilih Barang"
    }
  }, [_c('b-table', {
    attrs: {
      "responsive": "",
      "striped": "",
      "bordered": "",
      "fields": _vm.fields,
      "items": _vm.barangs
    },
    scopedSlots: _vm._u([{
      key: "cell(action)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('b-form-radio', {
          attrs: {
            "value": item,
            "name": "barang"
          },
          model: {
            value: _vm.selectedBarang,
            callback: function callback($$v) {
              _vm.selectedBarang = $$v;
            },
            expression: "selectedBarang"
          }
        })];
      }
    }, {
      key: "cell(barang)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.barang ? item.barang.nama : "-") + " ")];
      }
    }, {
      key: "cell(satuan)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.barang && item.barang.satuan ? item.barang.satuan.satuan : "-") + " ")];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12"
    }
  }, [_c('b-card', [_c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "4",
      "md": "4",
      "lg": "4",
      "xl": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Buat Penyimpanan Baru untuk barang ini?"
    }
  }, [_c('b-form-radio', {
    attrs: {
      "name": "some-radios",
      "value": "2"
    },
    model: {
      value: _vm.mode,
      callback: function callback($$v) {
        _vm.mode = $$v;
      },
      expression: "mode"
    }
  }, [_vm._v("Buat Penyimpanan baru")]), _c('b-form-radio', {
    attrs: {
      "name": "some-radios",
      "value": "1"
    },
    model: {
      value: _vm.mode,
      callback: function callback($$v) {
        _vm.mode = $$v;
      },
      expression: "mode"
    }
  }, [_vm._v("Pilih penyimpanan yang sudah ada")])], 1)], 1)], 1), _vm.mode == 2 ? [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "3",
      "xl": "3"
    }
  }, [_c('b-form-group', [_c('validation-provider', {
    attrs: {
      "name": "blok",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('label', {
          attrs: {
            "for": ""
          }
        }, [_vm._v("Pilih Blok")]), _c('b-input-group', [_c('b-form-select', {
          attrs: {
            "options": _vm.bloks,
            "required": !_vm.form.blok_id,
            "reduce": function reduce(option) {
              return option.value;
            },
            "label": "text"
          },
          on: {
            "change": function change($event) {
              return _vm.getPalet();
            }
          },
          model: {
            value: _vm.form.blok_id,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "blok_id", $$v);
            },
            expression: "form.blok_id"
          }
        }), _c('b-input-group-append', [_c('b-button', {
          attrs: {
            "variant": "outline-success"
          },
          on: {
            "click": function click($event) {
              _vm.showModalBlok = true;
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "PlusIcon"
          }
        })], 1)], 1)], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2512588209)
  })], 1), _c('b-modal', {
    attrs: {
      "cancel-variant": "secondary",
      "ok-title": "Tambah",
      "cancel-title": "Batal",
      "centered": "",
      "title": "Form Tambah"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('section', {
          staticClass: "d-flex justify-content-end align-items-center"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "success"
          },
          on: {
            "click": _vm.submitblok
          }
        }, [_vm._v(" Tambah ")]), _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              _vm.showModalBlok = false;
            }
          }
        }, [_vm._v(" Keluar ")])], 1)];
      },
      proxy: true
    }], null, false, 4068226420),
    model: {
      value: _vm.showModalBlok,
      callback: function callback($$v) {
        _vm.showModalBlok = $$v;
      },
      expression: "showModalBlok"
    }
  }, [_c('b-form', [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "blok"
    }
  }, [_vm._v("Nama Blok")]), _c('b-form-input', {
    attrs: {
      "placeholder": "Nama blok"
    },
    model: {
      value: _vm.formblok.blok,
      callback: function callback($$v) {
        _vm.$set(_vm.formblok, "blok", $$v);
      },
      expression: "formblok.blok"
    }
  })], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "3",
      "xl": "3"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Pilih Palet")]), _c('b-input-group', [_c('b-form-select', {
    attrs: {
      "options": _vm.palets,
      "required": !_vm.form.palet_id,
      "reduce": function reduce(option) {
        return option.value;
      },
      "label": "text"
    },
    on: {
      "change": function change($event) {
        return _vm.getRak();
      }
    },
    model: {
      value: _vm.form.palet_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "palet_id", $$v);
      },
      expression: "form.palet_id"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "variant": "outline-success"
    },
    on: {
      "click": function click($event) {
        _vm.showModalPalet = true;
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "PlusIcon"
    }
  })], 1)], 1)], 1)], 1), _c('b-modal', {
    attrs: {
      "cancel-variant": "secondary",
      "ok-title": "Tambah",
      "cancel-title": "Batal",
      "centered": "",
      "title": "Form Tambah"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('section', {
          staticClass: "d-flex justify-content-end align-items-center"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "success"
          },
          on: {
            "click": _vm.submitpalet
          }
        }, [_vm._v(" Tambah ")]), _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              _vm.showModalPalet = false;
            }
          }
        }, [_vm._v(" Keluar ")])], 1)];
      },
      proxy: true
    }], null, false, 2647605716),
    model: {
      value: _vm.showModalPalet,
      callback: function callback($$v) {
        _vm.showModalPalet = $$v;
      },
      expression: "showModalPalet"
    }
  }, [_c('b-form', [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "blok"
    }
  }, [_vm._v("Blok")]), _c('b-form-select', {
    attrs: {
      "options": _vm.bloks,
      "label": "Blok"
    },
    model: {
      value: _vm.form.blok_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "blok_id", $$v);
      },
      expression: "form.blok_id"
    }
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": "pallet"
    }
  }, [_vm._v("Nama Palet")]), _c('b-form-input', {
    attrs: {
      "id": "pallet",
      "placeholder": "Nama Palet"
    },
    model: {
      value: _vm.formpalet.palet,
      callback: function callback($$v) {
        _vm.$set(_vm.formpalet, "palet", $$v);
      },
      expression: "formpalet.palet"
    }
  })], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "3",
      "xl": "3"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Pilih Rak (optional)")]), _c('b-input-group', [_c('b-form-select', {
    attrs: {
      "options": _vm.raks,
      "reduce": function reduce(option) {
        return option.value;
      },
      "label": "text"
    },
    on: {
      "change": function change($event) {
        return _vm.getLaci();
      }
    },
    model: {
      value: _vm.form.rak_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "rak_id", $$v);
      },
      expression: "form.rak_id"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "variant": "outline-success"
    },
    on: {
      "click": function click($event) {
        _vm.showModalRak = true;
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "PlusIcon"
    }
  })], 1)], 1)], 1)], 1), _c('b-modal', {
    attrs: {
      "cancel-variant": "secondary",
      "ok-title": "Tambah",
      "cancel-title": "Batal",
      "centered": "",
      "title": "Form Tambah"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('section', {
          staticClass: "d-flex justify-content-end align-items-center"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "success"
          },
          on: {
            "click": _vm.submitrak
          }
        }, [_vm._v(" Tambah ")]), _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              _vm.showModalRak = false;
            }
          }
        }, [_vm._v(" Keluar ")])], 1)];
      },
      proxy: true
    }], null, false, 4111069972),
    model: {
      value: _vm.showModalRak,
      callback: function callback($$v) {
        _vm.showModalRak = $$v;
      },
      expression: "showModalRak"
    }
  }, [_c('b-form', [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "blok"
    }
  }, [_vm._v("Blok")]), _c('b-form-select', {
    attrs: {
      "options": _vm.bloks,
      "label": "Blok"
    },
    model: {
      value: _vm.form.blok_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "blok_id", $$v);
      },
      expression: "form.blok_id"
    }
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": "palet"
    }
  }, [_vm._v("Palet")]), _c('b-form-select', {
    attrs: {
      "options": _vm.palets,
      "label": "Palet"
    },
    model: {
      value: _vm.form.palet_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "palet_id", $$v);
      },
      expression: "form.palet_id"
    }
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": "rak"
    }
  }, [_vm._v("Nama Rak")]), _c('b-form-input', {
    attrs: {
      "id": "rak",
      "placeholder": "Nama Rak"
    },
    model: {
      value: _vm.formrak.rak,
      callback: function callback($$v) {
        _vm.$set(_vm.formrak, "rak", $$v);
      },
      expression: "formrak.rak"
    }
  })], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "3",
      "xl": "3"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Pilih Laci (optional)")]), _c('b-input-group', [_c('b-form-select', {
    attrs: {
      "options": _vm.lacis,
      "reduce": function reduce(option) {
        return option.value;
      },
      "label": "text"
    },
    model: {
      value: _vm.form.laci_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "laci_id", $$v);
      },
      expression: "form.laci_id"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "variant": "outline-success"
    },
    on: {
      "click": function click($event) {
        _vm.showModalLaci = true;
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "PlusIcon"
    }
  })], 1)], 1)], 1)], 1), _c('b-modal', {
    attrs: {
      "cancel-variant": "secondary",
      "ok-title": "Tambah",
      "cancel-title": "Batal",
      "centered": "",
      "title": "Form Tambah"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('section', {
          staticClass: "d-flex justify-content-end align-items-center"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "success"
          },
          on: {
            "click": _vm.submitlaci
          }
        }, [_vm._v(" Tambah ")]), _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              _vm.showModalLaci = false;
            }
          }
        }, [_vm._v(" Keluar ")])], 1)];
      },
      proxy: true
    }], null, false, 2174611476),
    model: {
      value: _vm.showModalLaci,
      callback: function callback($$v) {
        _vm.showModalLaci = $$v;
      },
      expression: "showModalLaci"
    }
  }, [_c('b-form', [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "blok"
    }
  }, [_vm._v("Blok")]), _c('b-form-select', {
    attrs: {
      "options": _vm.bloks,
      "label": "Blok"
    },
    model: {
      value: _vm.form.blok_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "blok_id", $$v);
      },
      expression: "form.blok_id"
    }
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": "palet"
    }
  }, [_vm._v("Palet")]), _c('b-form-select', {
    attrs: {
      "options": _vm.palets,
      "label": "Palet"
    },
    model: {
      value: _vm.form.palet_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "palet_id", $$v);
      },
      expression: "form.palet_id"
    }
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": "rak"
    }
  }, [_vm._v("Rak")]), _c('b-form-select', {
    attrs: {
      "options": _vm.raks,
      "label": "Rak"
    },
    model: {
      value: _vm.form.rak_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "rak_id", $$v);
      },
      expression: "form.rak_id"
    }
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": "laci"
    }
  }, [_vm._v("Nama Laci")]), _c('b-form-input', {
    attrs: {
      "id": "laci",
      "placeholder": "Nama Laci"
    },
    model: {
      value: _vm.formlaci.laci,
      callback: function callback($$v) {
        _vm.$set(_vm.formlaci, "laci", $$v);
      },
      expression: "formlaci.laci"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('b-row', [_vm.optkonversi.length > 0 ? _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "4",
      "xl": "4"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "nilai"
    }
  }, [_vm._v("Jumlah")]), _c('b-form-input', {
    attrs: {
      "type": "number",
      "id": "nilai",
      "placeholder": "nilai"
    },
    model: {
      value: _vm.nilai,
      callback: function callback($$v) {
        _vm.nilai = $$v;
      },
      expression: "nilai"
    }
  })], 1)], 1) : _vm._e(), _vm.optkonversi.length > 0 ? _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "4",
      "xl": "4"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "konversi"
    }
  }, [_vm._v("Konversi")]), _c('b-form-select', {
    attrs: {
      "reduce": function reduce(option) {
        return option.value;
      },
      "options": _vm.optkonversi,
      "id": "konversi",
      "placeholder": "konversi"
    },
    model: {
      value: _vm.id_satuan,
      callback: function callback($$v) {
        _vm.id_satuan = $$v;
      },
      expression: "id_satuan"
    }
  })], 1)], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "4",
      "xl": "4"
    }
  }, [_c('b-form-group', [_vm.optkonversi.length > 0 ? _c('label', {
    attrs: {
      "for": "jumlah"
    }
  }, [_vm._v("Jumlah Hasil Konversi")]) : _vm._e(), _vm.optkonversi == null || 0 || [] ? _c('label', {
    attrs: {
      "for": "jumlah"
    }
  }, [_vm._v("Jumlah")]) : _vm._e(), _c('b-input-group', {
    attrs: {
      "append": _vm.selectedBarang ? _vm.selectedBarang.barang.satuan.satuan : '-'
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "id": "jumlah",
      "placeholder": "Jumlah"
    },
    model: {
      value: _vm.form.jumlah,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "jumlah", $$v);
      },
      expression: "form.jumlah"
    }
  })], 1)], 1)], 1)], 1), _c('b-form-group', [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.addBarang($event);
      }
    }
  }, [_vm._v("Tambah")])], 1)] : _vm._e(), _vm.mode == 1 ? [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_vm.loaded ? _c('b-table', {
    attrs: {
      "striped": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "items": _vm.items,
      "fields": _vm.fieldsPenyimpananBarangs
    },
    scopedSlots: _vm._u([{
      key: "cell(penyimpanan)",
      fn: function fn(data) {
        return [_c('b-form-checkbox', {
          attrs: {
            "value": data.item
          },
          model: {
            value: _vm.pilihpenyimpanan,
            callback: function callback($$v) {
              _vm.pilihpenyimpanan = $$v;
            },
            expression: "pilihpenyimpanan"
          }
        })];
      }
    }, {
      key: "cell(no)",
      fn: function fn(row) {
        return [_c('strong', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(row.index + 1) + " ")])];
      }
    }, {
      key: "cell(kode)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(item.barang ? item.barang.kode : '-') + " ")];
      }
    }, {
      key: "cell(nama)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(item.barang ? item.barang.nama : '-') + " ")];
      }
    }, {
      key: "cell(blok)",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(item.blok ? item.blok.blok : '-') + " ")];
      }
    }, {
      key: "cell(palet)",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_vm._v(" " + _vm._s(item.palet ? item.palet.palet : '-') + " ")];
      }
    }, {
      key: "cell(rak)",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_vm._v(" " + _vm._s(item.rak ? item.rak.rak : '-') + " ")];
      }
    }, {
      key: "cell(laci)",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_vm._v(" " + _vm._s(item.laci ? item.laci.laci : '-') + " ")];
      }
    }, {
      key: "cell(stok)",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_vm._v(" " + _vm._s(item.stok ? item.stok : 0) + " ")];
      }
    }, {
      key: "cell(satuan)",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [_vm._v(" " + _vm._s(item.barang ? item.barang.satuan.satuan : item.barang_id) + " ")];
      }
    }], null, false, 1042493535)
  }) : _vm._e()], 1)], 1), _c('b-row', [_vm.optkonversi.length > 0 ? _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "4",
      "xl": "4"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "nilai"
    }
  }, [_vm._v("Jumlah")]), _c('b-form-input', {
    attrs: {
      "type": "number",
      "id": "nilai",
      "placeholder": "nilai"
    },
    model: {
      value: _vm.nilai,
      callback: function callback($$v) {
        _vm.nilai = $$v;
      },
      expression: "nilai"
    }
  })], 1)], 1) : _vm._e(), _vm.optkonversi.length > 0 ? _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "4",
      "xl": "4"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "konversi"
    }
  }, [_vm._v("Konversi")]), _c('b-form-select', {
    attrs: {
      "reduce": function reduce(option) {
        return option.value;
      },
      "options": _vm.optkonversi,
      "id": "konversi",
      "placeholder": "konversi"
    },
    model: {
      value: _vm.id_satuan,
      callback: function callback($$v) {
        _vm.id_satuan = $$v;
      },
      expression: "id_satuan"
    }
  })], 1)], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "4",
      "xl": "4"
    }
  }, [_c('b-form-group', [_vm.optkonversi.length > 0 ? _c('label', {
    attrs: {
      "for": "jumlah"
    }
  }, [_vm._v("Jumlah Hasil Konversi")]) : _vm._e(), _vm.optkonversi.length == 0 ? _c('label', {
    attrs: {
      "for": "jumlah"
    }
  }, [_vm._v("Jumlah")]) : _vm._e(), _c('b-input-group', {
    attrs: {
      "append": _vm.selectedBarang ? _vm.selectedBarang.barang.satuan.satuan : '-'
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "id": "jumlah",
      "placeholder": "Jumlah"
    },
    model: {
      value: _vm.form.jumlah,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "jumlah", $$v);
      },
      expression: "form.jumlah"
    }
  })], 1)], 1)], 1)], 1), _c('b-form-group', [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.addBarang($event);
      }
    }
  }, [_vm._v("Tambah")])], 1)] : _vm._e()], 2)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', [_c('b-table', {
    attrs: {
      "fields": _vm.fieldsAddedItems,
      "bordered": "",
      "striped": "",
      "responsive": "",
      "items": _vm.addedItems
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function fn(row) {
        return [_vm._v(" " + _vm._s(row.index + 1) + " ")];
      }
    }, {
      key: "cell(lokasi)",
      fn: function fn(_ref13) {
        var item = _ref13.item;
        return [_c('strong', [_vm._v(" " + _vm._s(item.lokasi) + " ")])];
      }
    }, {
      key: "cell(action)",
      fn: function fn(row) {
        return [_c('b-link', {
          staticClass: "text-danger",
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.addedItems.splice(row.index, 1);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "size": "24",
            "icon": "XCircleIcon"
          }
        })], 1)];
      }
    }])
  }), _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_vm._v(" Simpan ")])], 1)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }